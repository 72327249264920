<template>
  <div ref="elTransition" class="page-transition-simple">
    <div class="_curtain"></div>
    <div class="_body">
      <p class="_loading">Loading ...</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';
import type { TTransitionExpose } from '~/components/transitions/pageTransition/transition/transition.type.js';
import { ELenisEvents } from '~/lib/lenis/lenis.enum.js';
import './simple.css';

defineExpose<TTransitionExpose>({
  beforeEnter,
  enter,
  afterEnter,
  beforeLeave,
  leave,
  afterLeave,
})

const { $gsap, $splitText } = useNuxtApp()

const elTransition = ref()
let isAnimating = false

let elCurtain: HTMLElement
let elLoading: HTMLElement
let loadingSplit: SplitText

onMounted(() => {
  elCurtain = elTransition?.value.querySelector('._curtain')
  elLoading = elTransition?.value.querySelector('._loading')

  loadingSplit = new $splitText(elLoading, {
    type: 'words, chars',
    wordsClass: 'word',
    charsClass: 'char',
  })

  $gsap.set(loadingSplit.chars, { y: '100%' })
})

function beforeEnter(el: HTMLElement) { }

function enter(el: HTMLElement, callback: () => void) {
  if (isAnimating) return
  isAnimating = true

  // Scroll to top
  document.dispatchEvent(
    new CustomEvent(ELenisEvents.SCROLL_TO, {
      detail: { target: 0, immediate: true },
    }),
  )

  // Anim
  $gsap
    .timeline({
      onComplete: () => {
        // console.log('Enter completed')
        isAnimating = false
        callback()
      },
    })
    .set(elCurtain, { y: 0, opacity: 1 })
    .fromTo(
      loadingSplit.chars,
      { y: 0 },
      { y: '-100%', duration: 0.4, stagger: 0.01, ease: 'expo.out' },
    )
    .to(
      elCurtain,
      {
        y: '-100%',
        duration: 0.8,
        ease: 'expo.out',
        opacity: 1,
      },
      '<+=0.2',
    )
}

function afterEnter(el: HTMLElement) {
  document.dispatchEvent(new CustomEvent(ELenisEvents.SCROLL_UNLOCK))
}

function beforeLeave(el: HTMLElement) {
  document.dispatchEvent(new CustomEvent(ELenisEvents.SCROLL_LOCK))
}

function leave(el: HTMLElement, callback: () => void) {
  if (isAnimating) return
  isAnimating = true

  // Anim
  $gsap
    .timeline({
      onComplete: () => {
        // console.log('Leave completed')
        isAnimating = false
        callback()
      },
    })
    .set(elCurtain, { y: '100%', opacity: 1 })
    .to(elCurtain, {
      y: 0,
      duration: 1.2,
      ease: 'expo.out',
      opacity: 1,
    })
    .fromTo(
      loadingSplit.chars,
      { y: '100%' },
      { y: 0, duration: 1, stagger: 0.03, ease: 'expo.out' },
      '<+=0.1',
    )
}

function afterLeave(el: HTMLElement) { }
</script>
